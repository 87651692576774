import React, { useRef, useCallback, forwardRef, useMemo, useEffect } from "react"
import { useAppContext } from "@app/providers/app"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useAlgoliaSearch } from "@app/providers/algolia"
import { InputGroup, Input, useDisclosure, Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, Box, Text } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { SearchResultsPanel } from "../SearchResultsPanel"
import { useCore } from "@app/hooks/useCore"

type SearchWidgetAltProps = {
  location: any
  isPinned?: boolean
}

type SearchExperinceProps = {
  location: any
  isPinned?: boolean
}

export const SearchWidgetAlt: React.FC<SearchWidgetAltProps> = ({ location, isPinned }) => {
  const memoLocation = useMemo(() => location.href, [location.href])
  const {
    helpers: { isBrowser },
  } = useCore()

  return isBrowser ? <SearchExpereince location={memoLocation} isPinned={isPinned} /> : null
}

const SearchExpereince: React.FC<SearchExperinceProps> = ({ location, isPinned = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setSearchTerm } = useAlgoliaSearch()
  const dummyRef = useRef<HTMLFormElement>(null)
  const searchPanelRef = useRef<HTMLDivElement>(null)
  const topOffset = dummyRef.current?.getBoundingClientRect().top ?? 0
  const { dispatch } = useAppContext()
  const scrolledPassHeader = window.scrollY > 160

  const handleCloseSearch = () => {
    dispatch({
      type: "search",
      payload: false,
    })
    onClose()
    setSearchTerm("")
  }

  const modalParentStyles = css`
    -webkit-overflow-scrolling: auto;
    overscroll-behavior-y: none;
  `

  const modalContentStyles = css`
    width: 100% !important;
    max-width: 100%;
    margin: ${scrolledPassHeader ? "62px" : "calc(var(--announcement-height) + 62px)"} auto 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    height: calc(100dvh - ${topOffset}px);
    max-height: calc(100dvh - ${topOffset}px);
    -webkit-overflow-scrolling: auto;
    overscroll-behavior-y: none;

    @media screen and (min-width: 900px) {
      max-width: 800px !important;
      margin-top: ${scrolledPassHeader ? "16px" : "calc(var(--announcement-height) + 16px)"};
      height: auto;
    }
  `

  const modalHeaderStyles = css`
    padding: 0 20px 16px 20px;
    margin: 0;

    @media screen and (min-width: 900px) {
      padding: 0 48px;
      width: calc(clamp(100%, 1440px, 100vw) - 72px - 15px - 188px - 250px);
      margin: 0 auto 0;
      position: relative;
      top: -1px;
      left: -38px;
    }

    @media screen and (min-width: 1200px) {
      padding: 0;
      width: calc(clamp(calc(100vw - 96px), 100%, 800px) - 72px - 15px - 188px - 250px);
      max-width: 800px;
    }
  `

  const modalBodyStyles = css`
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overscroll-behavior-y: none;

    @media screen and (min-width: 900px) {
      max-width: 800px;
      overflow: visible;
    }

    @media screen and (min-width: 1200px) {
      margin-left: -36px;
    }
  `
  return (
    <>
      <SearchFormDummy handleClick={onOpen} ref={dummyRef} />
      <Modal css={modalParentStyles} isOpen={isOpen} onClose={handleCloseSearch} scrollBehavior="inside" motionPreset="Fade">
        <ModalOverlay backgroundColor={["transparent"]} />
        <ModalContent
          css={modalContentStyles}
          onTouchMove={() => {
            searchPanelRef.current?.focus()
          }}
          ref={searchPanelRef}
        >
          <ModalHeader css={modalHeaderStyles}>
            <SearchForm />
          </ModalHeader>
          <ModalBody css={modalBodyStyles}>
            <SearchResultsPanel location={location} isPinned={isPinned} onClose={handleCloseSearch} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const SearchFormDummy = forwardRef(function SearchFormDummy(props, ref: React.Ref<HTMLFormElement>) {
  const { handleClick } = props as { handleClick: () => void }
  const { searchTerm } = useAlgoliaSearch()
  const locales = useLocale(LOCALE_KEYS.SEARCH)

  return (
    <>
      <div onClick={handleClick} ref={ref}>
        <InputGroup bg="transparent">
          <Box
            fontSize="16px"
            textOverflow="ellipsis"
            h={[10, 10, 12]}
            borderRadius="50px"
            bg="brand.inputBg"
            display="flex"
            alignItems="center"
            overflow="hidden"
            pl={[4, 4, 6]}
            pr={15}
            w="100%"
          >
            <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {searchTerm && searchTerm !== "" ? searchTerm : locales?.headerSearchBarPlaceholder}
            </Text>
          </Box>
        </InputGroup>
      </div>
    </>
  )
})

const SearchForm = () => {
  const { searchTerm, setSearchTerm, submitSearchTerm } = useAlgoliaSearch()
  const inputRef = useRef<HTMLInputElement>(null)
  const locales = useLocale(LOCALE_KEYS.SEARCH)
  const { state, dispatch } = useAppContext()

  const showResultsPanel = useCallback(() => {
    dispatch({
      type: "search",
      payload: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.activeSearch])

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault()
          submitSearchTerm()
        }}
      >
        <InputGroup ref={inputRef} bg="transparent">
          <Input
            placeholder={locales?.headerSearchBarPlaceholder}
            variant="search"
            type="text"
            fontSize="16px"
            aria-label="Open search"
            pr={15}
            textOverflow="ellipsis"
            h={[10, 10, 12]}
            value={searchTerm}
            onFocus={showResultsPanel}
            onChange={event => setSearchTerm(event.target.value)}
          />
        </InputGroup>
      </form>
    </>
  )
}
