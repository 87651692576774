import { memo, useMemo, useState, useEffect, useCallback, useRef, createContext, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

interface FilterMapping {
  key: string
  displayName: string
}

interface SortOption {
  name: string
  handle: string
}

interface DefaultSortOption {
  handle: string
  name: string
}

interface AlgoliaConfig {
  applicationId: string
  searchOnlyApiKey: string
  numberOfProductsPerPage: number
  filterNameMappings: FilterMapping[]
  sortOptions: SortOption[]
  defaultSortOptions: DefaultSortOption[]
}

interface AlgoliaSearchProviderProps {
  location?: Location
  children: React.ReactNode
  collection?: {
    headerFilter?: string
    shopify?: {
      id: string
      handle: string
    }
  }
  mode?: "instant-search" | "regular"
}

interface AlgoliaSearchResponse {
  hits: any[]
  nbPages: number
  page: number
  facets: Record<string, any>
  queryID?: string
}

interface AlgoliaSearchState {
  loading: boolean
  searchTerm: string
  products: null | any[]
  facets: Record<string, any>
  totalPages: number
  pagesToShow: number[]
  hasPreviousPage: boolean
  hasNextPage: boolean
  selectedTopFilters: string[]
  selectedFacetFilters: string[]
  sortOption: Partial<SortOption>
  currentPage: number
  queryID?: string
}

interface AlgoliaContextType extends Omit<AlgoliaSearchState, "loading"> {
  config: AlgoliaConfig
  sortOptions: SortOption[]
  defaultSortOptions: DefaultSortOption[]
  handlePageChange: (page: number) => void
  handleSideBarFiltersChange: (key: string, value: string | string[]) => void
  handleSortOptionChange: (sortOptionHandle: string) => void
  handleTopFilterChange: (name: string) => void
  setSearchTerm: (term: string) => void
  setCurrentPage: (page: number) => void
  submitSearchTerm: () => void
  updateSearchParam: (query: string) => void
  trackProductClick: (objectID: string, position: number) => void
  trackProductAddedToCart: (objectID: string, price: number, discount: number, quantity: number) => void
}

const CONSTANTS = {
  DEFAULT_SORT_OPTION: "Best Sellers",
  DEFAULT_PAGE: 1,
  EXCLUDED_URL_PARAMS: ["q", "page", "sort_by"],
  RETRY_ATTEMPTS: 3,
  DEBOUNCE_DELAY: 50,
  MAX_VISIBLE_PAGES: 5,
} as const

const urlUtils = {
  updateUrl: (queryParams: URLSearchParams): void => {
    try {
      const newUrl = new URL(window.location.href)
      newUrl.search = queryParams.toString()
      window.history.pushState({}, "", newUrl.toString())
    } catch (error) {
      console.error("Error updating URL:", error)
    }
  },

  updateQueryParam: (key: string, value: string): void => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set(key, value)
    urlUtils.updateUrl(queryParams)
  },

  deleteAllQueryParams: (): void => {
    const queryParams = new URLSearchParams()
    urlUtils.updateUrl(queryParams)
  },

  deleteQueryParam: (key: string): void => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.delete(key)
    urlUtils.updateUrl(queryParams)
  },

  getQueryParams: (): URLSearchParams => new URLSearchParams(window.location.search),
}

const filterUtils = {
  encodeToKebabCase: (str: string): string => {
    const specialCases: Record<string, string> = {
      "Title A-Z": "title-a-z",
      "Title Z-A": "title-z-a",
    }
    return specialCases[str] ?? str.toLowerCase().replace(/\s+/g, "-")
  },

  decodeFromKebabCase: (str: string): string => {
    const specialCases: Record<string, string> = {
      "title-a-z": "Title A-Z",
      "title-z-a": "Title Z-A",
    }
    return specialCases[str] ?? str.replace(/-+/g, " ").replace(/(?:^|\s)\S/g, a => a.toUpperCase())
  },

  createReverseMapping: (filterNameMapping: FilterMapping[]): Record<string, string> =>
    filterNameMapping.reduce(
      (acc, mapping) => ({
        ...acc,
        [filterUtils.encodeToKebabCase(mapping.displayName)]: mapping.key,
      }),
      {}
    ),

  encodeFilters: (filters: string[], filterNameMapping: FilterMapping[]): Record<string, string[]> =>
    filters.reduce((acc, filter) => {
      const [key, value] = filter.split(":")
      const mappedKey = filterNameMapping.find(mapping => mapping.key === key)?.displayName

      if (!mappedKey || !value) return acc

      const formattedKey = filterUtils.encodeToKebabCase(mappedKey)
      return {
        ...acc,
        [formattedKey]: [...(acc[formattedKey] || []), value],
      }
    }, {} as Record<string, string[]>),

  decodeFilters: (queryString: string, filterNameMapping: FilterMapping[]): string[] => {
    if (!queryString) return []

    const reverseMapping = filterUtils.createReverseMapping(filterNameMapping)
    const cleanQuery = queryString.startsWith("?") ? queryString.slice(1) : queryString

    return cleanQuery.split("&").reduce((filters: string[], param) => {
      const [key, encodedValues] = param.split("=")
      if (!encodedValues || !reverseMapping[key]) return filters

      try {
        const decodedValues = JSON.parse(decodeURIComponent(encodedValues))
        return [...filters, ...decodedValues.map(value => `${reverseMapping[key]}:${value}`)]
      } catch (error) {
        console.warn(`Failed to parse values for ${key}:`, error)
        return filters
      }
    }, [])
  },
}

const algoliaApi = {
  async performRequest({
    sortOption,
    searchTerm,
    selectedFacetFilters,
    page,
    config,
    mode,
  }: {
    sortOption: Partial<SortOption>
    searchTerm: string
    selectedFacetFilters: string[]
    page: number
    config: AlgoliaConfig
    mode?: "instant-search" | "regular" | null
  }): Promise<AlgoliaSearchResponse> {
    if (!sortOption.handle) {
      throw new Error("Invalid sort option")
    }
    const url = `https://${config.applicationId}.algolia.net/1/indexes/${sortOption.handle}/query`

    const hasCollectionFilter = selectedFacetFilters.some(filter =>
      Array.isArray(filter) ? filter.some(f => f.startsWith("collections:")) : filter.startsWith("collections:")
    )

    function groupFilters(filters) {
      const groups = {}
      const order = []

      filters.forEach(filter => {
        const [prefix, ...rest] = filter.split(":")
        if (!groups[prefix]) {
          groups[prefix] = []
          order.push(prefix)
        }
        groups[prefix].push(filter)
      })

      const result = order.map(prefix => {
        const items = groups[prefix]
        return items.length === 1 ? items[0] : items
      })

      return result
    }
    selectedFacetFilters = groupFilters(selectedFacetFilters)

    const requestBody = {
      query: searchTerm || "",
      filters: hasCollectionFilter ? "" : "NOT tags:review-product",
      facets: ["*"],
      facetFilters: mode === "instant-search" ? ["*"] : selectedFacetFilters.length ? selectedFacetFilters : ["*"],
      hitsPerPage: mode === "instant-search" ? 4 : config.numberOfProductsPerPage,
      page: page - 1,
      distinct: 1,
      clickAnalytics: true,
    }

    let lastError: Error | null = null

    for (let i = 0; i < CONSTANTS.RETRY_ATTEMPTS; i++) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "X-Algolia-Application-Id": config.applicationId,
            "X-Algolia-API-Key": config.searchOnlyApiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        })

        if (!response.ok) {
          throw new Error(`Algolia request failed: ${response.statusText}`)
        }

        return await response.json()
      } catch (error) {
        lastError = error as Error
        if (i === CONSTANTS.RETRY_ATTEMPTS - 1) break
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000))
      }
    }

    throw lastError || new Error("Failed to perform Algolia request")
  },
}

const AlgoliaSearchContext = createContext<AlgoliaContextType | undefined>(undefined)

const AlgoliaSearchProvider: React.FC<AlgoliaSearchProviderProps> = ({ location, collection, mode = null, children }) => {
  const { config } = useStaticQuery<{ config: AlgoliaConfig }>(graphql`
    query StaticAlgolia {
      config: sanitySettingAlgolia {
        applicationId
        searchOnlyApiKey
        numberOfProductsPerPage
        filterNameMappings {
          key
          displayName
        }
        sortOptions {
          name
          handle
        }
        defaultSortOptions {
          handle
          name
        }
      }
    }
  `)

  const [state, setState] = useState<AlgoliaSearchState>({
    loading: true,
    searchTerm: "",
    products: null,
    facets: {},
    totalPages: 0,
    pagesToShow: [],
    hasPreviousPage: false,
    hasNextPage: false,
    selectedTopFilters: [],
    selectedFacetFilters: [],
    sortOption: {},
    currentPage: CONSTANTS.DEFAULT_PAGE,
  })
  const [userToken, setUserToken] = useState<string>("")

  const isFirstAlgoliaQuery = useRef(true)
  const abortController = useRef<AbortController | null>(null)

  const sendInsightsEvent = useCallback(
    async (events: Array<Record<string, any>>) => {
      const userToken = localStorage.getItem("algolia_user_token")
      if (!config.searchOnlyApiKey || !userToken) return

      try {
        await fetch("https://insights.algolia.io/1/events", {
          method: "POST",
          headers: {
            "X-Algolia-Application-Id": config.applicationId,
            "X-Algolia-API-Key": config.searchOnlyApiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            events: events.map(event => ({
              ...event,
              userToken,
            })),
          }),
        })
      } catch (error) {
        console.error("Error sending Insights event:", error)
      }
    },
    [config.applicationId, config.searchOnlyApiKey, userToken]
  )

  const handlePageChange = useCallback(
    (page: number) => {
      if (state.currentPage === page) return
      setState(prev => ({ ...prev, currentPage: page }))
      urlUtils.updateQueryParam("page", page.toString())
    },
    [state.currentPage]
  )

  const handleSideBarFiltersChange = useCallback(
    (key: string, value: string | string[]) => {
      setState(prev => {
        const values = Array.isArray(value) ? value : [value]
        const existingFilters = new Set(prev.selectedFacetFilters)
        const addedFilters: string[] = []
        const removedFilters: string[] = []

        const updatedFilters = values.reduce((acc, v) => {
          const filterString = `${key}:${v}`
          if (existingFilters.has(filterString)) {
            removedFilters.push(filterString)
            return acc.filter(filter => filter !== filterString)
          } else {
            addedFilters.push(filterString)
            return [...acc, filterString]
          }
        }, prev.selectedFacetFilters)

        const events = []
        if (addedFilters.length) {
          events.push({
            eventType: "click",
            eventName: "Filter Applied",
            index: prev.sortOption.handle,
            filters: addedFilters,
          })
        }
        if (removedFilters.length) {
          events.push({
            eventType: "click",
            eventName: "Filter Removed",
            index: prev.sortOption.handle,
            filters: removedFilters,
          })
        }
        if (events.length) {
          sendInsightsEvent(events)
        }
        const groupedFilters = filterUtils.encodeFilters(updatedFilters, config.filterNameMappings)
        const queryParams = urlUtils.getQueryParams()

        Object.entries(groupedFilters).forEach(([key, values]) => {
          if (values.length) {
            queryParams.set(key, JSON.stringify(values))
          } else {
            queryParams.delete(key)
          }
        })

        urlUtils.updateUrl(queryParams)
        return {
          ...prev,
          currentPage: CONSTANTS.DEFAULT_PAGE,
          selectedFacetFilters: updatedFilters,
        }
      })
    },
    [config.filterNameMappings, sendInsightsEvent]
  )

  const handleSortOptionChange = useCallback(
    (sortOptionHandle: string) => {
      const selectedOption = config.sortOptions.find(option => option.handle === sortOptionHandle) || {}
      setState(prev => ({ ...prev, sortOption: selectedOption }))
      urlUtils.updateQueryParam("sort_by", filterUtils.encodeToKebabCase(selectedOption.name))
    },
    [config.sortOptions]
  )

  const handleTopFilterChange = useCallback(
    (name: string) => {
      setState(prev => {
        if (name === "show-all") {
          urlUtils.deleteAllQueryParams()
          if (collection?.headerFilter) {
            const removedFilters = prev.selectedTopFilters.map(filter => `named_tags.${collection.headerFilter}:${filter}`)
            if (removedFilters.length) {
              sendInsightsEvent(
                removedFilters.map(filter => ({
                  eventType: "click",
                  eventName: "Filter Removed",
                  index: prev.sortOption.handle,
                  filters: [filter],
                }))
              )
            }
          }
          return {
            ...prev,
            selectedTopFilters: [],
            selectedFacetFilters: [],
            currentPage: CONSTANTS.DEFAULT_PAGE,
          }
        }

        const wasSelected = prev.selectedTopFilters.includes(name)
        const newFilters = wasSelected ? prev.selectedTopFilters.filter(filter => filter !== name) : [...prev.selectedTopFilters, name]

        if (collection?.headerFilter) {
          const filterString = `named_tags.${collection.headerFilter}:${name}`
          sendInsightsEvent([
            {
              eventType: "click",
              eventName: wasSelected ? "Filter Removed" : "Filter Applied",
              index: prev.sortOption.handle,
              filters: [filterString],
            },
          ])
        }

        if (collection?.headerFilter) {
          if (newFilters.length) {
            urlUtils.updateQueryParam(collection.headerFilter, JSON.stringify(newFilters))
          } else {
            urlUtils.deleteQueryParam(collection.headerFilter)
          }
        }

        return {
          ...prev,
          selectedTopFilters: newFilters,
          currentPage: CONSTANTS.DEFAULT_PAGE,
        }
      })
    },
    [collection?.headerFilter, sendInsightsEvent]
  )

  const queryAlgolia = useCallback(
    async (
      page: number,
      searchTerm: string,
      collection: AlgoliaSearchProviderProps["collection"],
      selectedFacetFilters: string[] = [],
      selectedTopFilters: string[] = [],
      sortOption: Partial<SortOption>
    ) => {
      if (abortController.current) {
        abortController.current.abort()
      }
      abortController.current = new AbortController()

      const getFacetFilters = () => {
        let filters = [...selectedFacetFilters]

        if (collection?.shopify) {
          const { id, handle } = collection.shopify
          if (id) {
            const collectionIdFilter = `collection_ids:${id}`
            if (!filters.includes(collectionIdFilter)) {
              filters.push(collectionIdFilter)
            }
          } else if (handle) {
            const collectionHandleFilter = `collections:${handle}`
            if (!filters.includes(collectionHandleFilter)) {
              filters.push(collectionHandleFilter)
            }
          }
        }

        if (collection?.headerFilter && selectedTopFilters.length) {
          const headerFilter = `named_tags.${collection.headerFilter}`
          filters = filters
            .filter(filter => !filter.startsWith(headerFilter))
            .concat(selectedTopFilters.map(filter => `${headerFilter}:${filter}`))
        }

        return filters
      }

      try {
        const filters = getFacetFilters()
        if (isFirstAlgoliaQuery.current && filters.length > 1) {
          const initialResponse = await algoliaApi.performRequest({
            sortOption,
            searchTerm,
            selectedFacetFilters: collection?.shopify?.id ? [`collection_ids:${collection.shopify.id}`] : [],
            page,
            config,
            mode,
          })
          setState(prev => ({ ...prev, facets: initialResponse.facets }))
          isFirstAlgoliaQuery.current = false
        }

        const response = await algoliaApi.performRequest({
          sortOption,
          searchTerm,
          selectedFacetFilters: getFacetFilters(),
          page,
          config,
          mode,
        })

        if (!abortController.current) return

        const visiblePages =
          response.page < 3
            ? Array.from({ length: Math.min(5, response.nbPages) }, (_, i) => i)
            : Array.from(
                { length: CONSTANTS.MAX_VISIBLE_PAGES },
                (_, i) => Math.max(0, Math.min(response.nbPages - CONSTANTS.MAX_VISIBLE_PAGES, response.page - 2)) + i
              ).filter(p => p < response.nbPages)

        if (response.queryID && response.hits.length > 0) {
          sendInsightsEvent([
            {
              eventType: "view",
              eventName: "Products Viewed",
              index: sortOption.handle,
              queryID: response.queryID,
              objectIDs: response.hits.map(hit => hit.objectID).slice(0, 20),
            },
          ])
        }

        // add the queryID to each product in response.hits
        response.hits.forEach(hit => {
          hit.queryID = response.queryID
        })

        setState(prev => ({
          ...prev,
          queryID: response.queryID,
          products: response.hits,
          totalPages: response.nbPages,
          facets: isFirstAlgoliaQuery.current ? response.facets : prev.facets,
          pagesToShow: visiblePages.filter(p => p >= 0 && p < response.nbPages),
          hasNextPage: response.page + 1 < response.nbPages,
          hasPreviousPage: response.page > 0,
        }))

        isFirstAlgoliaQuery.current = false
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error in queryAlgolia:", error)
          setState(prev => ({
            ...prev,
            products: [],
            totalPages: 0,
            pagesToShow: [],
            hasNextPage: false,
            hasPreviousPage: false,
          }))
        }
      }
    },
    [config, collection]
  )

  const submitSearchTerm = useCallback(() => {
    setState(prev => ({ ...prev, currentPage: CONSTANTS.DEFAULT_PAGE }))
    window.location.href = `/search?q=${encodeURIComponent(state.searchTerm)}`
  }, [state.searchTerm])

  const updateSearchParam = useCallback((query: string) => {
    urlUtils.updateQueryParam("q", query)
  }, [])

  const trackProductAddedToCart = useCallback(
    (objectID: string, price: number, discount: number, quantity: number) => {
      if (!state.queryID) return

      sendInsightsEvent([
        {
          eventType: "conversion",
          eventName: "Product Added To Cart",
          index: state.sortOption.handle,
          objectIDs: [objectID],
          objectData: [
            {
              queryID: state.queryID,
              price: price,
              discount: discount,
              quantity: quantity,
            },
          ],
          currency: "AUD",
        },
      ])
    },
    [state.queryID, state.sortOption.handle, sendInsightsEvent]
  )

  const trackProductClick = useCallback(
    (objectID: string, index: number) => {
      if (!state.queryID) return
      const position = index + 1

      sendInsightsEvent([
        {
          eventType: "click",
          eventName: "Product Clicked",
          index: state.sortOption.handle,
          queryID: state.queryID,
          objectIDs: [objectID],
          positions: [position],
        },
      ])
    },
    [state.queryID, state.sortOption.handle, sendInsightsEvent]
  )

  useEffect(() => {
    const setupInitialState = () => {
      try {
        setState(prev => ({ ...prev, loading: true }))
        const queryParams = urlUtils.getQueryParams()
        const queryFromUrl = queryParams.get("q") || ""
        const page = parseInt(queryParams.get("page") || String(CONSTANTS.DEFAULT_PAGE), 10)

        const cleanQueryParams = new URLSearchParams(window.location.search)
        CONSTANTS.EXCLUDED_URL_PARAMS.forEach(param => {
          cleanQueryParams.delete(param)
        })

        const filters = cleanQueryParams.toString() ? filterUtils.decodeFilters(cleanQueryParams.toString(), config.filterNameMappings) : []

        const headerFilter =
          collection?.headerFilter && queryParams.get(collection.headerFilter)
            ? JSON.parse(queryParams.get(collection.headerFilter) || "[]")
            : []

        const defaultSortOption =
          config.defaultSortOptions.find(option => option.handle === collection?.shopify?.handle)?.name || CONSTANTS.DEFAULT_SORT_OPTION

        const sortBy = queryParams.get("sort_by") ? filterUtils.decodeFromKebabCase(queryParams.get("sort_by") || "") : defaultSortOption

        const newSortOption = config.sortOptions.find(option => option.name === sortBy) || {}

        setState(prev => ({
          ...prev,
          loading: false,
          searchTerm: mode !== "instant-search" ? queryFromUrl : prev.searchTerm,
          currentPage: page,
          selectedFacetFilters: filters,
          selectedTopFilters: headerFilter,
          sortOption: newSortOption,
        }))
      } catch (error) {
        console.error("Error in setupInitialState:", error)
        setState(prev => ({ ...prev, loading: false }))
      }
    }

    setupInitialState()
  }, [config.filterNameMappings, config.sortOptions, config.defaultSortOptions, collection?.headerFilter, mode])

  // Search effect
  useEffect(() => {
    let isMounted = true

    const updateSearch = async () => {
      if (!state.loading && (state.searchTerm || collection) && isMounted) {
        await queryAlgolia(
          state.currentPage,
          state.searchTerm,
          collection,
          state.selectedFacetFilters,
          state.selectedTopFilters,
          state.sortOption
        )
      }
    }

    updateSearch()

    return () => {
      isMounted = false
      abortController.current?.abort()
    }
  }, [
    state.currentPage,
    state.searchTerm,
    collection,
    state.selectedFacetFilters,
    state.selectedTopFilters,
    state.sortOption,
    state.loading,
    queryAlgolia,
  ])

  // PopState effect for browser navigation
  useEffect(() => {
    if (window.location.pathname.includes("collection") && !collection) {
      return
    }

    let timeoutId: NodeJS.Timeout

    const handlePopState = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        try {
          setState(prev => ({ ...prev, loading: true }))
          const queryParams = urlUtils.getQueryParams()
          const queryFromUrl = queryParams.get("q") || ""
          const page = parseInt(queryParams.get("page") || String(CONSTANTS.DEFAULT_PAGE), 10)

          const cleanQueryParams = new URLSearchParams(window.location.search)
          CONSTANTS.EXCLUDED_URL_PARAMS.forEach(param => {
            cleanQueryParams.delete(param)
          })

          const filters = cleanQueryParams.toString()
            ? filterUtils.decodeFilters(cleanQueryParams.toString(), config.filterNameMappings)
            : []

          const headerFilter =
            collection?.headerFilter && queryParams.get(collection.headerFilter)
              ? JSON.parse(queryParams.get(collection.headerFilter) || "[]")
              : []

          const sortBy = queryParams.get("sort_by")
            ? filterUtils.decodeFromKebabCase(queryParams.get("sort_by") || "")
            : CONSTANTS.DEFAULT_SORT_OPTION

          const newSortOption = config.sortOptions.find(option => option.name === sortBy) || {}

          setState(prev => ({
            ...prev,
            loading: false,
            searchTerm: mode !== "instant-search" ? queryFromUrl : prev.searchTerm,
            currentPage: page,
            selectedFacetFilters: filters,
            selectedTopFilters: headerFilter,
            sortOption: newSortOption,
          }))
        } catch (error) {
          console.error("Error in handlePopState:", error)
          setState(prev => ({ ...prev, loading: false }))
        }
      }, CONSTANTS.DEBOUNCE_DELAY)
    }

    window.addEventListener("popstate", handlePopState)
    return () => {
      window.removeEventListener("popstate", handlePopState)
      clearTimeout(timeoutId)
    }
  }, [collection, mode, config.filterNameMappings, config.sortOptions])

  useEffect(() => {
    if (location && location.search === "") {
      handleTopFilterChange("show-all")
    }
  }, [location])

  const generateFallbackUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c === "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("algolia_user_token")
    if (storedToken) {
      setUserToken(storedToken)
    } else {
      const newToken = self.crypto.randomUUID ? self.crypto.randomUUID() : generateFallbackUUID()
      localStorage.setItem("algolia_user_token", newToken)
      setUserToken(newToken)
    }
  }, [])

  const contextValue = useMemo(
    () => ({
      config,
      products: state.products,
      facets: state.facets,
      totalPages: state.totalPages,
      pagesToShow: state.pagesToShow,
      hasPreviousPage: state.hasPreviousPage,
      currentPage: state.currentPage,
      hasNextPage: state.hasNextPage,
      sortOption: state.sortOption,
      sortOptions: config.sortOptions,
      defaultSortOptions: config.defaultSortOptions,
      searchTerm: state.searchTerm,
      selectedTopFilters: state.selectedTopFilters,
      selectedFacetFilters: state.selectedFacetFilters,
      handlePageChange,
      handleSideBarFiltersChange,
      handleSortOptionChange,
      handleTopFilterChange,
      setSearchTerm: (term: string) => setState(prev => ({ ...prev, searchTerm: term })),
      setCurrentPage: (page: number) => setState(prev => ({ ...prev, currentPage: page })),
      submitSearchTerm,
      updateSearchParam,
      trackProductClick,
      trackProductAddedToCart,
    }),
    [
      config,
      state,
      handlePageChange,
      handleSideBarFiltersChange,
      handleSortOptionChange,
      handleTopFilterChange,
      submitSearchTerm,
      updateSearchParam,
      trackProductClick,
      trackProductAddedToCart,
    ]
  )

  return <AlgoliaSearchContext.Provider value={contextValue}>{children}</AlgoliaSearchContext.Provider>
}

const useAlgoliaSearch = (): AlgoliaContextType => {
  const context = useContext(AlgoliaSearchContext)
  if (!context) {
    throw new Error(
      "useAlgoliaSearch must be used within an AlgoliaSearchProvider. " +
        "Please check if you have wrapped your component with AlgoliaSearchProvider."
    )
  }
  return context
}

const MemoAlgoliaSearchProvider = memo(AlgoliaSearchProvider)
export { MemoAlgoliaSearchProvider as AlgoliaSearchProvider, useAlgoliaSearch }
