import { Image, AspectRatio, Box, Skeleton, SkeletonText, Tag, Text } from "@chakra-ui/react"
import { ProductPrice } from "@components/Product/ProductPrice"
import { ProductReviewStars } from "@components/Product/ProductReviewStars"
import { useProductCardBadges } from "@app/hooks/useBadges"
import { BrandedProductTitle } from "@components/BrandedProductTitle"
import { memo, useMemo } from "react"
import { useImage } from "@app/hooks/useImage"

import type { ProductVariant } from "shopify-storefront-api-typings"

type ProductCardBadgeProps = {
  bgColor?: string
  text?: string
  badgeType?: string
  isPdp?: boolean
  tagVariant?: "pdp-card" | "pdp-image" | "pdp-price"
  discountPercentage?: string | null
}

const ProductCardImage = ({ item, variant, badgeType, isOutOfStock, isReview, maxWidth, disableLazyLoad, discountPercentage }: any) => {
  const { scaleShopifyImage } = useImage()
  const { getProductCardBadges } = useProductCardBadges()
  const { showBadge, badge } = getProductCardBadges({ badgeType: "image", allProductTags: item?.tags })

  const showOutOfStock = useMemo(() => isOutOfStock && !isReview, [isOutOfStock, isReview])

  const lazyLoad = disableLazyLoad ? "auto" : "lazy"

  const shopifyImg = maxWidth ? scaleShopifyImage(item?.images?.[0]?.src, `${maxWidth}x`) : item?.images?.[0]?.src
  const sanityImg = item?.image

  let algoliaProductVariantsMetafieldImg = ""
  if (item.meta?.algolia?.product_variants && variant) {
    algoliaProductVariantsMetafieldImg = item.meta.algolia.product_variants.find((v: { id: any }) => v.id === variant.id).imageSrc
  }

  if (!algoliaProductVariantsMetafieldImg && !shopifyImg && !sanityImg) {
    return (
      <AspectRatio width="full" ratio={1}>
        <Skeleton />
      </AspectRatio>
    )
  }

  return (
    <Box position="relative" width="full" border="solid" borderWidth={1} borderColor="brand.darkerSand">
      {showBadge && !showOutOfStock && (
        <ProductCardBadge bgColor={badge?.bgColor} text={badge?.text} badgeType={badgeType} discountPercentage={discountPercentage} />
      )}
      {/* TODO: add notify me badge text below to product settings */}
      {showOutOfStock && <ProductCardBadge bgColor="green30" text="Back Soon" badgeType="image" />}
      <AspectRatio ratio={1 / 1}>
        <Image
          src={algoliaProductVariantsMetafieldImg || shopifyImg || sanityImg}
          fit="contain"
          width="full"
          height="full"
          loading={lazyLoad}
        />
      </AspectRatio>
    </Box>
  )
}

const ProductCardPrice = ({
  variant,
  appliedDiscount,
  isPlp,
}: {
  variant: ProductVariant
  appliedDiscount?: number | undefined
  isPlp?: boolean
}) => {
  if (!variant) {
    return <SkeletonText noOfLines={1} mb={3} />
  }

  return <ProductPrice variant={variant} size="sm" mb={[0, 3]} appliedDiscount={appliedDiscount} isPlp={isPlp} />
}

const ProductCardStars = ({ product, isPlp = false, productId }: { product: any; isPlp?: boolean; productId?: string }) => (
  <Box mt={[4, isPlp ? 2 : 10]} mb={3} className="productStars">
    <ProductReviewStars product={product} productId={productId} />
  </Box>
)

const ProductCardTitle = ({ item }: any) => {
  if (!item?.title) {
    return <SkeletonText noOfLines={2} mt={[0, 2.5, 2.5]} />
  }

  return (
    <Text noOfLines={2} mt={[0, 2.5, 2.5]} fontSize="sm" lineHeight="21px" minH="42px">
      <BrandedProductTitle item={item} />
    </Text>
  )
}

const ProductCardBadge = ({
  bgColor = "brand.offWhite",
  text,
  badgeType,
  isPdp = false,
  tagVariant = "product-card",
  discountPercentage,
}: ProductCardBadgeProps) => {
  if (!text) return null

  // If badge text is Speacial, show calculated savings instead
  const badgeText = discountPercentage && text.toLocaleLowerCase() === "special" ? discountPercentage : text

  const extraStyles: any =
    badgeType === "image" ? { position: "absolute", top: isPdp ? [4, 5] : 2, left: isPdp ? [4, 5] : 2, zIndex: 1 } : { pb: 2 }

  return (
    <Box {...extraStyles}>
      <Tag variant={tagVariant} bg={`brand.${bgColor}`}>
        {badgeText}
      </Tag>
    </Box>
  )
}

const MemoProductCardTitle = memo(ProductCardTitle)
const MemoProductCardStars = memo(ProductCardStars)
const MemoProductCardPrice = memo(ProductCardPrice)
const MemoProductCardImage = ProductCardImage
const MemoProductCardBadge = memo(ProductCardBadge)
export {
  MemoProductCardTitle as ProductCardTitle,
  MemoProductCardStars as ProductCardStars,
  MemoProductCardPrice as ProductCardPrice,
  MemoProductCardImage as ProductCardImage,
  MemoProductCardBadge as ProductCardBadge,
}
