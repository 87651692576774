import React, { useEffect, useRef, memo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link, Text, Box, Container, VStack, Heading, IconButton, Button } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useAlgoliaSearch } from "@app/providers/algolia"
import { Results } from "@components/Search/Results/Results"
import { SearchFormItemAlt } from "@components/Search/Form/SearchFormItemAlt"
import { BiX } from "react-icons/bi"

type SearchResultsPanelProps = {
  location: string
  isPinned?: boolean
}

const SearchResultsPanel: React.FC<SearchResultsPanelProps> = ({ location, onClose }) => {
  const { searchTerm, submitSearchTerm } = useAlgoliaSearch()
  const panelRef = useRef<HTMLDivElement>(null)
  const { products } = useAlgoliaSearch()
  const results = products

  const resultsShown = searchTerm && results && results.length > 0
  const [cachedLocation] = useState(location)

  const searchResultsPanel = css`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1px;
    padding: 0 0;
    overflow-y: auto;
    border-top: 1px solid #143b34;
    display: flex;
    flex-direction: column;
    height: 100%;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior-y: none;

    .resultsContainer {
      flex: 1 1 100%;
      padding-bottom: 42px;

      @media screen and (min-width: 900px) {
        padding-top: 42px;
        position: relative;
      }
    }

    @media screen and (min-width: 900px) {
      max-width: 800px;
      border-top: none;
      top: 70px;
      height: auto;
      border-radius: 16px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: 1200px) {
      left: -56px;
    }

    .closeWrapper {
      display: flex;
      justify-content: flex-end;

      @media screen and (min-width: 900px) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .closeButton {
      padding: 0;
      margin: 0 -20px 0 0;
      min-width: 0;
      width: 50px;
      height: 50px;
      border-radius: 0;
      border: none;
      background-color: transparent;
      font-size: 24px;
      color: #000;

      @media screen and (min-width: 900px) {
        margin: 0;
      }
    }

    .searchResults {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
    }

    @media screen and (min-width: 600px) {
      .searchResults {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
      }
    }

    .searchResult {
      display: none;
    }

    .searchResult:nth-of-type(1),
    .searchResult:nth-of-type(2),
    .searchResult:nth-of-type(3) {
      display: block;
    }

    @media screen and (min-width: 900px) {
      .searchResults {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 8px 16px;
      }

      .searchResult:nth-of-type(1),
      .searchResult:nth-of-type(2),
      .searchResult:nth-of-type(3),
      .searchResult:nth-of-type(4) {
        display: block;
      }
    }

    .viewAllButton {
      background-color: #fff;
      text-decoration: none;
      border-radius: 8px;
      min-width: 250px;

      &:hover {
        background-color: #143b34;
        color: #fff;
      }
    }
  `

  useEffect(() => {
    if (cachedLocation !== location) {
      onClose()
    }
  }, [location, cachedLocation, onClose])

  const { searchSettings } = useStaticQuery<GatsbyTypes.StaticSearchQuery>(graphql`
    query StaticSearch {
      searchSettings: sanitySettingSearch {
        trendingSearches {
          ... on SanityProduct {
            url
            title
            shopify {
              handle
            }
          }
          ... on SanityCollection {
            url
            title
            shopify {
              handle
            }
          }
        }
        recommendedSearches {
          ... on SanityProduct {
            url
            title
            shopify {
              handle
            }
          }
          ... on SanityCollection {
            url
            title
            shopify {
              handle
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Box ref={panelRef} css={searchResultsPanel} bg="transparent">
        <Container bg="brand.offWhite" className="resultsContainer">
          <Box className="closeWrapper">
            <IconButton icon={<BiX />} aria-label="Close Search" onClick={onClose} className="closeButton" />
          </Box>
          {resultsShown && (
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="7">
              <Heading size="h4">Results</Heading>
              <Text
                onClick={() => submitSearchTerm()}
                display={["block", "none"]}
                fontSize="16px"
                color="brand.avocado"
                textDecor="underline"
                _hover={{ cursor: "pointer", textDecor: "underline" }}
              >
                View more
              </Text>
            </Box>
          )}
          <Box>
            {searchTerm && (
              <Results
                listStyle={{}}
                listClassName="searchResults"
                pageSize={10}
                renderResultCardProduct={props => <SearchFormItemAlt {...props} />}
                renderNoResults={() => (
                  <Text mb={["2", "6"]} mt={["0"]}>
                    No results found
                  </Text>
                )}
                renderLoading={() => (
                  <Text mb={["2", "6"]} mt={["0"]}>
                    Loading...
                  </Text>
                )}
              />
            )}
            {resultsShown && (
              <Box textAlign="center" mb={[2, 8]}>
                <Button variant="outline" onClick={() => submitSearchTerm()} className="viewAllButton" display={["inline-block"]}>
                  View all
                </Button>
              </Box>
            )}
            <Box mt={["4"]}>
              {!resultsShown && searchSettings?.trendingSearches && (
                <Box mb="10">
                  <Heading size="h4" mb="4">
                    Trending searches
                  </Heading>
                  <VStack alignItems="flex-start">
                    {searchSettings.trendingSearches?.map((item: any) => (
                      <Link
                        key={item?.title}
                        href={`${item?.url}/${item.shopify.handle}`}
                        textDecor="underline"
                        color="brand.avocado"
                        _hover={{ textDecor: "underline" }}
                      >
                        {item?.title}
                      </Link>
                    ))}
                  </VStack>
                </Box>
              )}
              {searchSettings?.recommendedSearches && (
                <Box display={[resultsShown ? "none" : "block", "block"]}>
                  <Heading size="h4" mb="4">
                    Recommended
                  </Heading>
                  <VStack alignItems="flex-start">
                    {searchSettings.recommendedSearches?.map((item: any) => (
                      <Link
                        key={item?.title}
                        href={`${item?.url}/${item.shopify.handle}`}
                        textDecor="underline"
                        color="brand.avocado"
                        _hover={{ textDecor: "underline" }}
                      >
                        {item?.title}
                      </Link>
                    ))}
                  </VStack>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

const MemoResultsPanel = memo(SearchResultsPanel)
export { MemoResultsPanel as SearchResultsPanel }
