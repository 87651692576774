import { useMemo, useState, useCallback } from "react"
import { css } from "@emotion/react"
import { CustomLink } from "@components/Link"
import { Box, Stack } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { useRoutes } from "@app/hooks/useRoutes"
import { useOutOfStock } from "@app/hooks/useOutOfStock"
// import { useAnalytics } from "@app/hooks/useAnalytics"
import { ProductCardVariantSelector } from "@components/ProductCard/ProductCardVariantSelector"
import { ProductAddToCart } from "@components/Product/ProductAddToCart"
import { useSubscriptionContext } from "@app/providers/subscription"
import { useSubscriptions } from "@app/hooks/useSubscriptions"
import { useProductCardBadges } from "@app/hooks/useBadges"
import { ProductCardPrice, ProductCardTitle, ProductCardBadge } from "@app/components/ProductCard/ProductCardStyles"
import { ProductCardNotifyMe } from "@app/components/ProductCard/ProductCardNotifyMe"
import { useConfigContext } from "@app/providers/config"
import { useAlgoliaSearch } from "@app/providers/algolia"

const searchResult = css`
  .searchResultItem {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 16px;

    @media screen and (min-width: 600px) {
      gap: 0;
      height: 100%;
    }
  }

  .searchResultItem button {
    margin-bottom: 0;
  }

  @media screen and (min-width: 600px) {
    .searchResultItem {
      display: flex;
      flex-direction: column;
    }

    .searchResultItem__contents {
      flex: 1 0 auto;
    }
  }
`

const getSelectedVariant = ({ item, selectedVariantIdx }: { item: any; selectedVariantIdx: number }) => {
  const isSanityProduct = !!item?.shopify
  const isShopifyProduct = !isSanityProduct

  if (isSanityProduct && typeof item?.shopify?.raw === "string") {
    return JSON.parse(item?.shopify?.raw)?.variants?.[0]
  }

  if (isShopifyProduct) {
    const moreThanOneVariant = item?.variants?.length > 1

    if (moreThanOneVariant) {
      return item?.variants?.[selectedVariantIdx]
    } else {
      return item?.variants?.[0]
    }
  }
}

export const SearchFormItemAlt: React.FC<{ product: any; index: any; itemRef: any; handleClick: any }> = ({
  product,
  itemRef,
  handleClick,
}) => {
  const {
    settings: { routes },
  } = useConfigContext()

  const [selectedVariantIdx, setSelectedVariantIdx] = useState(0)
  const productId = `${product.id}`
  const { adminProductNormaliserAlgolia } = useShopify()
  const { trackProductClick } = useAlgoliaSearch()
  const { urlResolver } = useRoutes()
  const imageSize = 300
  const item = adminProductNormaliserAlgolia(product, routes)
  const link = urlResolver(item)
  const { getProductCardBadges } = useProductCardBadges()
  const { showBadge, badge } = getProductCardBadges({ badgeType: "card", allProductTags: item?.tags })

  const { checkIfSubscriptionProduct } = useSubscriptionContext()
  const { subscriptionDinkus } = useSubscriptions()
  const selectedVariant = useMemo(() => getSelectedVariant({ item, selectedVariantIdx }), [item, selectedVariantIdx])

  const { checkIfOutOfStock } = useOutOfStock()
  const isOutOfStock = checkIfOutOfStock({ variant: selectedVariant, product: item })

  type purchaseRecurrence = "monthly" | "one-time"
  const [purchaseRecurrence, setPurchaseRecurrence] = useState<purchaseRecurrence>("monthly")

  const { isSubscriptionProduct } = checkIfSubscriptionProduct({ productId })
  const subscriptionType = (isSubscriptionProduct as any)?.subscription_defaults?.storefront_purchase_options || null
  const isSubscriptionAndOneTimeProduct = subscriptionType === "subscription_and_onetime"
  const isSubscriptionCheckout = isSubscriptionAndOneTimeProduct
    ? purchaseRecurrence === "monthly"
      ? true
      : false
    : isSubscriptionProduct
    ? true
    : false
  const subscriptionDiscountPercentage = Number((isSubscriptionProduct as any)?.discount_amount) || undefined
  const subscriptionDinkusMessage = subscriptionDinkus?.dinkusMessage?.replace(`{{X%}}`, `${subscriptionDiscountPercentage}%`)

  let algoliaProductVariantsMetafieldImg = ""
  if (item.meta?.algolia?.product_variants && selectedVariant) {
    algoliaProductVariantsMetafieldImg = item.meta.algolia.product_variants.find((v: { id: any }) => v.id === selectedVariant.id).imageSrc
  }

  const subscriptionData = {
    isSubscriptionProduct,
    isSubscriptionCheckout,
    isSubscriptionAndOneTimeProduct,
    subscriptionDiscountPercentage,
    subscriptionDinkusMessage,
    purchaseRecurrence,
    setPurchaseRecurrence,
  }

  const handleTrackingClick = useCallback(() => {
    trackProductClick(product.objectID, index)
  }, [])

  if (product.tags.includes("review-product")) return

  return (
    <Box
      ref={itemRef}
      mb={[10, 6]}
      className={isOutOfStock ? "searchResult searchResult--out-of-stock" : "searchResult"}
      css={searchResult}
    >
      <Box className="searchResultItem">
        <CustomLink to={link.url} title={link.title} onClick={handleTrackingClick}>
          <Box
            border="1px solid #F5ECE4"
            sx={{
              aspectRatio: "1",
            }}
          >
            {item.product_image && <img src={algoliaProductVariantsMetafieldImg || item.product_image} width={imageSize} />}
          </Box>
        </CustomLink>
        <Stack direction="column" className="searchResultItem__contents">
          <CustomLink to={link.url} title={link.title} onClick={handleTrackingClick}>
            <Box mb={[1, 3]}>
              <ProductCardTitle item={item} />
            </Box>
            <ProductCardPrice
              variant={selectedVariant}
              appliedDiscount={subscriptionData?.purchaseRecurrence === "monthly" ? subscriptionData?.subscriptionDiscountPercentage : 0}
              isPlp={true}
            />
            {subscriptionData?.isSubscriptionAndOneTimeProduct ? (
              <ProductCardBadge bgColor="yellow" text={subscriptionData?.subscriptionDinkusMessage} badgeType="card" />
            ) : (
              showBadge && <ProductCardBadge bgColor={badge?.bgColor} text={badge?.text} badgeType="card" />
            )}
          </CustomLink>
          <ProductCardVariantSelector
            item={item}
            selectedVariantIdx={selectedVariantIdx}
            setSelectedVariantIdx={setSelectedVariantIdx}
            isPlp={true}
            isSubscriptionAndOneTimeProduct={subscriptionData?.isSubscriptionAndOneTimeProduct}
            recurrence={{
              purchaseRecurrence: subscriptionData?.purchaseRecurrence,
              setPurchaseRecurrence: subscriptionData?.setPurchaseRecurrence,
            }}
            appliedDiscount={subscriptionData?.subscriptionDiscountPercentage}
            stripSizeSuffix={true}
          />
          <Box mt="auto!important" pt="16px">
            {isOutOfStock ? (
              <ProductCardNotifyMe variant={selectedVariant} />
            ) : (
              <ProductAddToCart
                product={item}
                productId={`${productId}`}
                variant={selectedVariant}
                subscriptionData={{
                  isSubscriptionAndOneTimeProduct: subscriptionData?.isSubscriptionAndOneTimeProduct,
                  purchaseRecurrence: subscriptionData?.purchaseRecurrence,
                  subscriptionDiscountPercentage: subscriptionData?.subscriptionDiscountPercentage,
                }}
                isSubscriptionProduct={isSubscriptionProduct}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
