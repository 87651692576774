import { memo, useCallback, useMemo } from "react"
import { Button, HStack, Skeleton } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"
import { useAppContext } from "@app/providers/app"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useCore } from "@app/hooks/useCore"
import { QuantitySelector } from "@components/QuantitySelector"
import { useAlgoliaSearch } from "@app/providers/algolia"

import type { ProductVariant } from "shopify-storefront-api-typings"
import { useCartContext } from "@app/providers/cart"

type ProductAddToCartProps = {
  product: any
  loading?: boolean
  productId: string
  isSubscriptionProduct?: boolean
  height?: number | Array<number>
  variant?: ProductVariant
  fontSize?: string | Array<string>
  marginBottom?: number | Array<number>
  subscriptionData?: {
    isSubscriptionAndOneTimeProduct?: boolean
    purchaseRecurrence?: "monthly" | "one-time"
    subscriptionDiscountPercentage?: number
  }
}

const ProductAddToCart: React.FC<ProductAddToCartProps> = ({
  product,
  loading = false,
  isSubscriptionProduct,
  height,
  fontSize = ["sm", "sm", "md"],
  productId,
  variant,
  marginBottom = 0,
  subscriptionData,
}) => {
  const locales = useLocale(LOCALE_KEYS.CART)
  const { cart } = useCartContext()
  const { addToCart, loading: cartLoading, mutationLoading } = useCart()
  const { trackProductAddedToCart } = useAlgoliaSearch()

  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { state, dispatch } = useAppContext()

  const decodedVariantId = useMemo(() => decodeBase64(variant?.id), [decodeBase64, variant?.id])

  const attributes = useMemo(() => {
    if (subscriptionData?.isSubscriptionAndOneTimeProduct) {
      if (subscriptionData?.purchaseRecurrence === "monthly") {
        return [
          { key: "_oneTimeOrSubscription", value: "monthly" },
          { key: "_subscriptionDiscount", value: `${subscriptionData?.subscriptionDiscountPercentage}` },
        ]
      } else {
        return [
          { key: "_oneTimeOrSubscription", value: "one-time" },
          { key: "_subscriptionDiscount", value: `${subscriptionData?.subscriptionDiscountPercentage}` },
        ]
      }
    } else {
      return []
    }
  }, [
    subscriptionData?.isSubscriptionAndOneTimeProduct,
    subscriptionData?.purchaseRecurrence,
    subscriptionData?.subscriptionDiscountPercentage,
  ])

  const handleAddToCart = useCallback(async () => {
    const isEmptyCart = cart?.lines?.length === 0

    // add product.queryID to item attributes with _queryID key
    const queryId = product?.queryID
    const userToken = localStorage.getItem("algolia_user_token")
    if (queryId) {
      attributes.push({ key: "_algoliaQueryID", value: queryId })
      attributes.push({ key: "_algoliaUserToken", value: userToken })
    }

    await addToCart({
      productId: `gid://shopify/Product/${productId}`,
      variantId: decodedVariantId,
      attributes: attributes,
    })

    const objectID = product?.objectID
    trackProductAddedToCart(objectID, variant?.priceV2?.amount, subscriptionData?.subscriptionDiscountPercentage, 1)

    if (isEmptyCart && !cartLoading) {
      dispatch({
        type: "cart",
        payload: !state.activeCart,
      })
    }
  }, [cart?.lines?.length, addToCart, productId, decodedVariantId, attributes, cartLoading, dispatch, state.activeCart])

  const currLineInCart = useMemo(() => {
    return cart?.lines?.find(line => {
      const isMatchingVariant = line?.merchandise?.id === decodedVariantId

      // Check if all attributes are present in the line attributes
      const isMatchingAttributes = attributes.every(subAttr =>
        line?.attributes?.some(lineAttr => lineAttr.key === subAttr.key && lineAttr.value === subAttr.value)
      )

      if (isMatchingVariant && isMatchingAttributes) {
        return line
      }
      return null
    })
  }, [cart?.lines, decodedVariantId, attributes])

  const getButtonText = useCallback(() => {
    const addToCartTxt = locales?.addToCart || "Add to Cart"
    const addingToCartTxt = locales?.addingToCart || "Adding to Cart"
    const addedToCartTxt = locales?.addedToCart || "Added!"

    if (currLineInCart) return addedToCartTxt
    if (cartLoading) return addingToCartTxt

    return addToCartTxt
  }, [cartLoading, locales?.addToCart, locales?.addedToCart, locales?.addingToCart, currLineInCart])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buttonText = useMemo(() => getButtonText(), [getButtonText, currLineInCart])

  if (!variant) {
    return <Skeleton h={10} />
  }

  return (
    <HStack spacing={4} align="stretch">
      {currLineInCart ? (
        <QuantitySelector width="full" line={currLineInCart} height={height} mutationLoading={mutationLoading} />
      ) : (
        <Button
          height={height}
          variant="solid"
          w="full"
          p="unset"
          maxW="full"
          mb={marginBottom}
          onClick={handleAddToCart}
          fontSize={fontSize}
          isLoading={cartLoading || loading}
          isDisabled={cartLoading || loading || mutationLoading}
          minW="unset"
          loadingText={locales?.addingToCart || ""}
        >
          {buttonText}
        </Button>
      )}
    </HStack>
  )
}

const MemoProductaddToCart = memo(ProductAddToCart)
export { MemoProductaddToCart as ProductAddToCart }
