import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useCallback } from "react"

import type { ProductVariant } from "shopify-storefront-api-typings"
import type { Cart, CartDeliveryGroup, CartLine } from "@shopify/hydrogen-react/storefront-api-types"
import { useCartContext } from "@app/providers/cart"

type GetProductsArgs = {
  countryCode?: string
  handles?: string[]
  firstCollections?: number
  firstImages?: number
  firstMedia?: number
  metafieldIdentifiers?: any[]
  firstVariants?: number
}

const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShop()
  const { customer } = useCustomerContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const { countryCode, currencyCode } = useCartContext()
  const {
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE, GET_PRODUCTS_BY_HANDLE, GET_COLLECTIONS_BY_HANDLE },
    },
    helpers: { edgeNormaliser, encodeShopifyId },
  } = useCore()

  const cartNormaliser = useCallback(
    (cart: Cart): Omit<Cart, "lines" | "deliveryGroups"> & { lines: CartLine[]; deliveryGroups: CartDeliveryGroup[] } => ({
      ...cart,
      lines: (edgeNormaliser(cart?.lines) || []) as CartLine[],
      deliveryGroups: edgeNormaliser(cart?.deliveryGroups) as CartDeliveryGroup[],
    }),
    [edgeNormaliser]
  )

  const formatErrors = useCallback((errors: any) => {
    const formatted = errors?.message
      ? { message: errors?.message }
      : //@ts-ignore
        Object.assign(...errors.map((value: any) => ({ [value?.field?.[1]]: { code: value?.code, message: value?.message } })))
    return formatted
  }, [])

  const formatMoney = useCallback(
    (amount: number, currency = "AUD") =>
      new Intl.NumberFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
        style: "currency",
        currency: currency,
      }).format(amount),
    [shop?.primaryDomain?.localization?.country]
  )

  const formatDate = useCallback(
    (date: string) =>
      new Intl.DateTimeFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
        timeZone: "Australia/Melbourne",
      }).format(new Date(date)),
    [shop?.primaryDomain?.localization?.country]
  )

  const imageUrl = useCallback((src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false
    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }, [])

  const imageSrcSets = useCallback(
    (src: string, size: string | number) => {
      return (
        src?.includes(`shopify.com`) &&
        [1, 500, 1000, 1500, 2000]
          .filter(set => !size || (size && size >= set))
          .map(set => `${imageUrl(src, set)} ${set}w`)
          .join(`,`)
      )
    },
    [imageUrl]
  )

  const onSale = useCallback(
    (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price),
    []
  )

  const getHandle = useCallback((item: any) => item?.handle || item?.shopify?.handle, [])

  const addressNormaliser = useCallback(
    (address: any) => ({
      ...address,
      default: address?.id === customer?.defaultAddress?.id,
    }),
    [customer?.defaultAddress?.id]
  )

  const orderNormaliser = useCallback(
    (order: any) => ({
      ...order,
      lineItems: edgeNormaliser(order?.lineItems),
    }),
    [edgeNormaliser]
  )

  const ordersNormaliser = useCallback(
    (orders: any) => edgeNormaliser(orders)?.map((order: any) => ({ ...order, lineItems: edgeNormaliser(order?.lineItems) })),
    [edgeNormaliser]
  )

  const imageNormaliser = useCallback(
    (image: any, size: string | number = "") => ({
      alt: image?.altText || image?.alt || image?.asset?.alt || "",
      src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
      srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
    }),
    [imageSrcSets, imageUrl]
  )

  const videoNormaliser = useCallback(
    (video: any, size: string | number = "") => ({
      alt: video?.alt || "",
      preview: imageNormaliser(video?.previewImage || "", size),
      src: video?.sources
        ?.filter(({ format }: { format: string }) => format === "mp4")
        ?.sort((a: any, b: any) => (a.height < b.height ? 1 : -1))?.[0],
    }),
    [imageNormaliser]
  )

  const modelNormaliser = useCallback(
    (model: any, size: string | number = "") => ({
      alt: model?.alt || "",
      preview: imageNormaliser(model?.previewImage || "", size),
      sources: model?.sources,
    }),
    [imageNormaliser]
  )

  const priceFieldNormaliser = useCallback(
    (item: any, field = "") => {
      const price = field && item?.[field] ? item[field] : item
      return {
        amount: `${price?.amount}`,
        local: formatMoney(price?.amount),
        afterpay: formatMoney(price?.amount / 4),
        currencyCode: `${price?.currencyCode || price?.currency_code}`,
      }
    },
    [formatMoney]
  )

  const priceNormaliser = useCallback(
    (presentmentPrices: any, field = "") =>
      Object.assign(
        {},
        ...edgeNormaliser(presentmentPrices)
          .filter((item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (currencyCode || shop?.currencyCode))
          .map((item: any) => priceFieldNormaliser(item, field))
      ),
    [currencyCode, edgeNormaliser, priceFieldNormaliser, shop?.currencyCode]
  )

  const variantNormaliser = useCallback(
    (variant: ProductVariant | CartLine["merchandise"]) => ({
      ...variant,
      ...(variant?.image && { image: imageNormaliser(variant?.image) }),
      ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
    }),
    [edgeNormaliser, imageNormaliser]
  )

  const variantsNormaliser = useCallback(
    (variants: any) => edgeNormaliser(variants)?.map(variantNormaliser),
    [edgeNormaliser, variantNormaliser]
  )

  const productNormaliser = useCallback(
    (product: any) => ({
      ...product,
      collections:
        edgeNormaliser(product?.collections)?.map((collection: any) => ({
          ...collection,
          image: imageNormaliser(collection?.image),
        })) || [],
      images:
        edgeNormaliser(product?.images)?.length > 0
          ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
          : edgeNormaliser(product?.media)
              ?.filter((media: any) => media?.mediaContentType === "IMAGE")
              ?.map((media: any) => imageNormaliser(media?.image)),
      media: edgeNormaliser(product?.media)?.map((media: any) =>
        media?.mediaContentType === "VIDEO"
          ? videoNormaliser(media)
          : media?.mediaContentType === "IMAGE"
          ? imageNormaliser(media?.image)
          : null
      ),
      metafields: edgeNormaliser(product?.metafields),
      models: edgeNormaliser(product?.media)
        ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
        ?.map((media: any) => modelNormaliser(media)),
      variants: variantsNormaliser(product?.variants) || [],
      videos: edgeNormaliser(product?.media)
        ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
        ?.map((media: any) => videoNormaliser(media)),
    }),
    [edgeNormaliser, imageNormaliser, modelNormaliser, videoNormaliser, variantsNormaliser]
  )

  const staticVariantNormaliser = useCallback(
    (variant: ProductVariant) => ({
      ...variant,
      price: {
        amount: variant?.priceV2,
        currencyCode: shop?.currencyCode,
      },
      compareAtPrice: {
        amount: variant?.compareAtPriceV2,
        currencyCode: shop?.currencyCode,
      },
      priceV2: {
        amount: variant?.priceV2,
        currencyCode: shop?.currencyCode,
      },
      compareAtPriceV2: {
        amount: variant?.compareAtPriceV2,
        currencyCode: shop?.currencyCode,
      },
    }),
    [shop?.currencyCode]
  )

  const adminPriceNormaliser = useCallback(
    (presentmentPrices: any, field = "") =>
      Object.assign(
        {},
        ...presentmentPrices
          .filter((item: any) => (field && item?.[field] ? item[field] : item)?.currency_code === (currencyCode || shop?.currencyCode))
          .map((item: any) => priceFieldNormaliser(item, field))
      ),
    [currencyCode, priceFieldNormaliser, shop?.currencyCode]
  )

  const adminPresentmentPriceNormaliser = useCallback(
    (presentment_prices: any) =>
      presentment_prices?.map((presentmentPrice: any) => ({
        compareAtPrice: {
          amount: presentmentPrice?.compare_at_price?.amount,
          currencyCode: presentmentPrice?.compare_at_price?.currency_code,
        },
        price: {
          amount: presentmentPrice?.price?.amount,
          currencyCode: presentmentPrice?.price?.currency_code,
        },
      })),
    []
  )

  const adminProductNormaliser = useCallback(
    (product: any, config?: any) => {
      return {
        ...product,
        url: routes.PRODUCT,
        availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
        id: encodeShopifyId(product?.id, "Product"),
        images: product?.images?.map((image: any) => imageNormaliser(image, config?.imageSize || false)) || [],
        legacyId: product?.id,
        productType: product?.product_type,
        priceRange: {
          minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
          maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
        },
        variants: product?.variants?.map((variant: ProductVariant) => ({
          ...variant,
          availableForSale: variant?.available,
          id: encodeShopifyId(variant?.id, "ProductVariant"),
          legacyId: variant?.id,
          priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
          compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
        })),
      }
    },
    [adminPriceNormaliser, encodeShopifyId, imageNormaliser, priceNormaliser, routes.PRODUCT, adminPresentmentPriceNormaliser]
  )

  const adminProductNormaliserAlgolia = (product: any, routes?: any) => {
    function formatObjectToKeyValueStrings(obj) {
      const entries = []
      for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value)) {
          value.forEach(item => entries.push(`${key}:${item}`))
        } else {
          entries.push(`${key}:${value}`)
        }
      }
      return entries
    }

    const namedTags = formatObjectToKeyValueStrings(product?.named_tags || {})
    const tags = [...(product?.tags || []), ...namedTags]

    return {
      ...product,
      tags,
      ...(routes && { url: routes.PRODUCT }),
      availableForSale: product?.inventory_available,
      id: encodeShopifyId(product?.id, "Product"),
      productType: product?.product_type,
      priceRange: {
        minVariantPrice: product?.variants_min_price,
        maxVariantPrice: product?.variants_max_price,
      },
      variants: product.meta?.algolia?.product_variants
        ? product.meta.algolia.product_variants.map((variant: any) => ({
            ...variant,
            priceV2: { amount: variant?.price, currencyCode: "AUD" },
            compareAtPriceV2: { amount: variant?.compareAtPrice, currencyCode: "AUD" },
          }))
        : [
            {
              priceV2: { amount: product?.price || 0, currencyCode: "AUD" },
              compareAtPriceV2: { amount: product?.compare_at_price || 0, currencyCode: "AUD" },
            },
          ],
    }
  }

  const collectionNormaliser = useCallback(
    (collection: any) => ({
      ...collection,
      id: parseInt(collection?.shopify?.id) || collection?.id,
      handle: collection?.shopify?.handle || collection?.handle,
      image: imageNormaliser(collection?.image),
      ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
      products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
    }),
    [edgeNormaliser, imageNormaliser, productNormaliser]
  )

  const sellingPlanGroupsNormaliser = useCallback(
    (sellingPlanGroups: any) => {
      const normalisedSellingGroups = edgeNormaliser(sellingPlanGroups)
      const normalisedSellingGroupsAndSellingPlans = normalisedSellingGroups?.map((sellingGroup: any) => ({
        ...sellingGroup,
        sellingPlans: edgeNormaliser(sellingGroup?.sellingPlans),
      }))
      return normalisedSellingGroupsAndSellingPlans
    },
    [edgeNormaliser]
  )

  // Returns the collection back from Shopify with the matching handle
  // firstProducts: sets how many products to get from the collection
  // firstVariants: sets how many variants to get for each product
  // See https://gitlab.com/dotdevv/packages/headless-core/-/blob/master/app/src/graphql/queries/collection.ts
  const getCollection = useCallback(
    async ({
      firstCollections = 0,
      firstImages = 0,
      firstMedia = 0,
      metafieldIdentifiers = [],
      firstProducts = 0,
      firstVariants = 0,
      handle = "",
    }) => {
      const { data } = await client.query({
        query: GET_COLLECTION_PRODUCT_COMPLETE,
        variables: {
          countryCode,
          handle,
          firstCollections,
          firstImages,
          firstMedia,
          metafieldIdentifiers,
          firstProducts,
          firstVariants,
        },
      })

      return collectionNormaliser(data?.collection)
    },
    [GET_COLLECTION_PRODUCT_COMPLETE, client, collectionNormaliser, countryCode]
  )

  const getCollections = useCallback(
    async ({
      countryCode = "AU",
      handles = [],
      firstCollections = 0,
      firstImages = 0,
      firstMedia = 0,
      metafieldIdentifiers = [],
      firstVariants = 0,
    }) => {
      const { data } = await client.query({
        query: GET_COLLECTIONS_BY_HANDLE(handles),
        variables: {
          countryCode,
          firstCollections,
          firstImages,
          firstMedia,
          metafieldIdentifiers,
          firstVariants,
        },
      })

      //@ts-ignore
      return handles?.map(handle => collectionNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
    },
    [GET_COLLECTIONS_BY_HANDLE, client, collectionNormaliser]
  )

  const getProducts = useCallback(
    async ({
      countryCode = "AU",
      handles = [],
      firstCollections = 0,
      firstImages = 0,
      firstMedia = 0,
      metafieldIdentifiers = [],
      firstVariants = 0,
    }: GetProductsArgs) => {
      const { data } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(handles),
        variables: {
          countryCode,
          firstCollections,
          firstImages,
          firstMedia,
          metafieldIdentifiers,
          firstVariants,
        },
      })

      //@ts-ignore
      return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
    },
    [GET_PRODUCTS_BY_HANDLE, client, productNormaliser]
  )

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    imageNormaliser,
    orderNormaliser,
    ordersNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    staticVariantNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    adminProductNormaliserAlgolia,
    sellingPlanGroupsNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProducts,
    cartNormaliser,
  }
}

export { useShopify }
