import React, { useEffect, useMemo, useState } from "react"
import { useAlgoliaSearch } from "@app/providers/algolia"

interface ResultsProps {
  listStyle: any
  listClassName?: any
  renderNoResults: any
  // renderError: any
  // renderResultCardCallout: any
  renderResultCardProduct: any
  renderPaginationNumbered?: any
  renderLoading: any
}

const Results: React.FC<ResultsProps> = ({
  listStyle,
  listClassName,
  renderNoResults,
  // renderError,
  // renderResultCardCallout,
  renderResultCardProduct,
  renderPaginationNumbered,
  renderLoading,
}) => {
  const { products } = useAlgoliaSearch()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (products && products.length === 0) {
      setIsLoading(false)
    }
  }, [products])

  const productRefs = useMemo(() => products?.map(() => ({ current: null } as React.RefObject<HTMLDivElement>)) ?? [], [products])

  if (!products) {
    return renderLoading()
  }

  if (products.length === 0) {
    return isLoading ? renderLoading() : renderNoResults()
  }

  return (
    <>
      {/* {renderResultCardCallout()} */}
      {/* {renderError()} */}
      <section style={listStyle} className={listClassName}>
        {products.map((product, index) => renderResultCardProduct({ product, index, productRefs }))}
      </section>
      {renderPaginationNumbered && renderPaginationNumbered()}
    </>
  )
}

export { Results }
